import React, { Fragment, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { detailsUser } from '../actions/userActions';
import { ToolBar } from '../components/ToolBar';
import { AlertMessage } from '../components/AlertMessage';
import { LoadingBox } from '../components/LoadingBox';
import { listAttributes } from '../actions/attributeActions';
import { getNotificationsUser } from '../actions/notificationActions';
import { detailsOrder } from '../actions/orderActions';
import { CART_EMPTY } from '../constants/cartConstants';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Axios from 'axios';
import Swal from 'sweetalert2';
import { extracurricularActivityInscriptionDetails } from '../actions/extracurricularActivityInscriptionActions';
import { ExtracurricularActivityInscriptionButton } from '../components/ExtracurricularActivityInscriptionButton';
import { EXTRACURRICULARACTIVITYINSCRIPTION_PAYMENT_RESET } from '../constants/extracurricularActivityInscriptionConstants';

export const ExtracurricularActivityInscriptionScreen = (props) => {

	const params = useParams();

	const navigate = useNavigate();

	const dispatch = useDispatch();

	const extracurricularActivityInscriptionId = params.id

	const {loading, extracurricularActivityInscription, error} = useSelector((state) => state.extracurricularActivityInscriptionDetails);

	console.log(extracurricularActivityInscription);

	const {extracurricularActivityInscription: extracurricularActivityInscriptionPaid} = useSelector(state => state.extracurricularActivityInscriptionPaid)

	const userDetails = useSelector(state => state.userDetails)

	const [stripePromise, setStripePromise] = useState(null)

	const [authorizationGiven, setAuthorizationGiven] = useState(false)

	const userSignin = useSelector(state => state.userSignin)
	const {userInfo} = userSignin;

	const orderDetails = useSelector((state) => state.orderDetails);

	useEffect(() => {
		if (userInfo) {
			dispatch(listAttributes());
			dispatch(extracurricularActivityInscriptionDetails(extracurricularActivityInscriptionId));
			dispatch(detailsUser(userInfo._id))
			dispatch(getNotificationsUser());
		} else {
			navigate(`/`);
		}
	}, [dispatch, extracurricularActivityInscriptionId, userInfo, navigate])

	useEffect(() => {
		if (localStorage.getItem('orderId')) {
			dispatch(detailsOrder(localStorage.getItem('orderId')))
		}
	}, [dispatch])

	useEffect(() => {
		if (orderDetails.order?.isPaid) {
		  dispatch({ type: CART_EMPTY });
		  localStorage.removeItem('cartItems');
		  localStorage.removeItem('orderId');
		}
	  }, [orderDetails.order, dispatch])

	useEffect(() => {
		if (extracurricularActivityInscriptionPaid) {
			Swal.fire({
				position: 'center',
				icon: 'success',
				title: 'Actividad extraescolar pagada correctamente',
				showConfirmButton: false,
				timer: 1500
			})
			dispatch({type: EXTRACURRICULARACTIVITYINSCRIPTION_PAYMENT_RESET})
			navigate('/extracurricularactivityhistory')
		}
	}, [extracurricularActivityInscriptionPaid, dispatch, navigate])

	useEffect(() => {
		if (extracurricularActivityInscription) {
			const getStripeParams = async () => {
				let endpointUrl = '/api/stripe/config'
	
				if (process.env.REACT_APP_PAYMENT_METHOD !== 'stripe' || (extracurricularActivityInscription.alternativePayment && process.env.REACT_APP_ALTERNATIVE_PAYMENT_METHOD === 'stripe')) {
					endpointUrl +='/alternativepayment'
				}
				const {data} = await Axios.get(process.env.REACT_APP_DB_ADDRESS + endpointUrl)
				return (loadStripe(data?.publishableKey))
			}

			if (process.env.REACT_APP_PAYMENT_METHOD === 'stripe' || process.env.REACT_APP_ALTERNATIVE_PAYMENT_METHOD === 'stripe') {
				setStripePromise(getStripeParams())
			}
		}
	}, [extracurricularActivityInscription])

	return (
		<>
			<ToolBar titulo={extracurricularActivityInscription ? extracurricularActivityInscription.reference : ''} hitos={[
				{titulo: "Inicio", enlace: "/" },
				{titulo: extracurricularActivityInscription ? extracurricularActivityInscription.reference : '', enlace: "#" }]}/>
		
			<div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">						
				<div className="content flex-row-fluid" id="kt_content">  
					{loading ? (<LoadingBox variant={"primary"}/>) : 
					error ? (<AlertMessage variant={"danger"} message={error}/>) : (
						<div className="row g-5 g-xl-10 mb-5 mb-xl-10">    
							<div className="col-xl-7">        
								<div className="card card-flush h-lg-100">
									<div className="card-body p-10">
										<img className="img-fluid" src={extracurricularActivityInscription?.extracurricularActivity.image ? extracurricularActivityInscription?.extracurricularActivity.image : "/Grupo_183.png"} alt={extracurricularActivityInscription?.extracurricularActivity.name} />
									</div>            
								</div>        
							</div>        
							<div className="col-xl-5">
								<div className="card h-md-100">
									<div className="card-body d-flex flex-column">
										<h1>{extracurricularActivityInscription?.extracurricularActivity.name}</h1>
										<span style={{fontSize : `2rem`}}>
											{
												extracurricularActivityInscription?.fee?.price?.toFixed(2)
											} €
										</span>
										<p>{extracurricularActivityInscription?.extracurricularActivity.description}</p>
										<div>
											{
												userDetails?.user?.userChildren ?
												<div className="row mt-5">
													<label className="form-label fw-bold mb-2 mt-3">Estudiante</label>
													<div className="col-12">
														<select className="form-select" disabled>
															<option value="null">{extracurricularActivityInscription?.student.name}</option>
														</select>
													</div>
												</div>
												:
												<></>
											}
										</div>
										{
											extracurricularActivityInscription?.extracurricularActivity?.requiresAuthorization &&
											<div className='row mt-5'>
												<label className='form-check form-switch form-check-custom form-check-solid'>
													<input className='form-check-input' type='checkbox' checked={authorizationGiven} onChange={(e) => setAuthorizationGiven(e.target.checked)} />
													<span className='form-check-label fw-bold me-4'>{extracurricularActivityInscription?.extracurricularActivity?.authorizationText}</span>
												</label>
											</div>
										}
										<div className='row mt-5'>
											<label className='form-label fw-bold mb-2 mt-3'>Tipo de actividad</label>
											<div className='col-12'>
												<select className='form-select' disabled>
													<option value="null">{extracurricularActivityInscription?.fee.name}</option>
												</select>
											</div>
										</div>
										{
											(process.env.REACT_APP_PAYMENT_METHOD === 'stripe' || process.env.REACT_APP_ALTERNATIVE_PAYMENT_METHOD === 'stripe') && stripePromise && !extracurricularActivityInscription.active && (!extracurricularActivityInscription?.extracurricularActivity?.requiresAuthorization || (extracurricularActivityInscription?.extracurricularActivity?.requiresAuthorization && authorizationGiven)) &&
												<Elements stripe={stripePromise}>
													<ExtracurricularActivityInscriptionButton extracurricularActivityInscription={extracurricularActivityInscription} user={userDetails.user?.name}/>
												</Elements>
										}
									</div>
								</div>
							</div>
						</div>
						)
					}
				</div>
			</div>
		</>
	)
}