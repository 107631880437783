import Axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router'
import { Link } from 'react-router-dom';
import { createCategory, listCategories } from '../actions/categoryActions';
import { detailsExtracurricularActivity, updateExtracurricularActivity } from '../actions/extracurricularActivityActions';
import { AlertMessage } from '../components/AlertMessage';
import { LoadingBox } from '../components/LoadingBox';
import { ToolBar } from '../components/ToolBar';
import { EXTRACURRICULARACTIVITY_UPDATE_RESET } from '../constants/extracurricularActivityConstants';
import Select from "react-select"
import { listCourses } from '../actions/courseActions';
import { CustomDataTable } from '../components/CustomDataTable';
import Swal from 'sweetalert2';
import { CATEGORIE_CREATE_RESET } from '../constants/categorieConstants';
import { getNotificationsUser } from '../actions/notificationActions';
import { cancelExtracurricularActivityInscription, getSalesExtracurricularActivity, manageInscription } from '../actions/extracurricularActivityInscriptionActions';
import { EXTRACURRICULARACTIVITYINSCRIPTION_CANCEL_RESET, EXTRACURRICULARACTIVITYINSCRIPTION_MANAGE_RESET } from '../constants/extracurricularActivityInscriptionConstants';
import removeAccents from '../utils/removeAccents';
import { listStudents } from '../actions/studentActions';

export const EditExtracurricularActivityScreen = (props) => {

    const navigate = useNavigate();
    const params = useParams();
    const { id: extracurricularActivityId } = params;

    const [name, setName] = useState('')
    const [sku, setSku] = useState('')
    const [image, setImage] = useState('')
    const [description, setDescription] = useState('')
    const [cancelAt, setCancelAt] = useState(null)
    const [startAt, setStartAt] = useState(null)
    const [active, setActive] = useState(false)
    const [billable, setBillable] = useState(false)
    const [catalogMode, setCatalogMode] = useState(false)
    const [requiresAuthorization, setRequiresAuthorization] = useState(false)
    const [authorizationText, setAuthorizationText] = useState(null)

    const [categoryName, setCategoryName] = useState('')
    const [categoryDescription, setCategoryDescription] = useState('')
    const [categoryShow, setCategoryShow] = useState(true)

    const [currentCategories, setCategories] = useState([])

    const extracurricularActivityDetails = useSelector(state => state.extracurricularActivityDetails);
    const { loading, error, extracurricularActivity } = extracurricularActivityDetails;

    const extracurricularActivityUpdate = useSelector(state => state.extracurricularActivityUpdate);
    const { loading: loadingUpdate, error: errorUpdate, success: successUpdate } = extracurricularActivityUpdate;

    const categoryCreated = useSelector(state => state.categoryCreate)

    const categorieList = useSelector((state) => state.categorieList);
    const { loading: loadingCategory, error: errorCategory, categories } = categorieList;

    const courseList = useSelector((state) => state.courseList);
    const { loading: loadingCourses, error: errorCourses, courses } = courseList;

    const { loading: loadingStudents, error: errorStudents, students } = useSelector(state => state.studentList)

    const {loading: loadingManageInscription, error: errorManageInscription, success} = useSelector(state => state.extracurricularActivityInscriptionManagement)

    const {error: errorCancel, success: successCancel, loading: loadingCancel} = useSelector(state => state.extracurricularActivityInscriptionCancel)

    const salesExtracurricularActivity = useSelector(state => state.salesExtracurricularActivity)

    const [options, setOptions] = useState([])
    const selectedCategories = [];
    const [selectedOptions, setSelectedOptions] = useState([])

    const [coursesOptions, setCoursesOptions] = useState([])
    const [selectedCourses, setSelectedCourses] = useState([])

    const [studentsOptions, setStudentsOptions] = useState([])
    const [selectedStudents, setSelectedStudents] = useState([])

    const [fees, setFees] = useState([])

    const [subscriptionsFiltered, setSubscriptionsFiltered] = useState([])
    const [minDate, setMinDate] = useState('')
    const [maxDate, setMaxDate] = useState('')
    const [alternativePayment, setAlternativePayment] = useState(false)

    const dispatch = useDispatch();

    const closeModalBtn = useRef();

    const PERIODS = {"day": "Diario", "week": "Semanal", "month": "Mensual", "year": "Anual"}

    const columns = [
        {
        name: "REFERENCIA",
        selector: (row) => removeAccents(row.extracurricularactivityinscriptionReference),
        sortable: true,
        cell: (row) => <span>{row.extracurricularactivityinscriptionReference}</span>,
        },
        {
        name: "ACTIVO DESDE",
        selector: (row) => removeAccents(row.isPaid),
        sortable: true,          
        cell: (row) => <span>{row.isPaid ? row.paidAt?.substring(0, 10): 'No'}</span>,
        },
        {
          name: "PRODUCTO",
          selector: (row) => removeAccents(`${row.extracurricularActivity?.name}, ${row.fee?.name}`),
          sortable: true,
          cell: row => <span>{row.extracurricularActivity?.name}, {row.fee?.name}</span>
        },
        {
          name: "RECURRENCIA",
          selector: row => removeAccents(`${row.fee.price.toFixed(2)}€, ${PERIODS[row.fee.period]}`),
          sortable: true,
          cell: row => <span>{`${row.fee.price.toFixed(2)}€, ${PERIODS[row.fee.period]}`}</span>
        },
        {
          name: "CLIENTE",
          selector: row => removeAccents(row.user?.name),
          sortable: true,
          cell: row => <span>{row.user?.name}</span>
        },
        {
          name: "ALUMNO",
          selector: row => removeAccents(row.student?.name),
          sortable: true,
          cell: row => <span>{row.student?.name}</span>
        },
        {
            name: "Acciones",
            selector: (row) => removeAccents(row.extracurricularactivityinscriptionReference),
            sortable: false,
            cell: row =>
                row.accepted === null || row.accepted === undefined
                ?
                    <>
                        <i className="bi bi-check-lg px-2" style={{fontSize: '1.4rem', cursor: 'pointer'}} onClick={() => manageExtracurricularActivityInscription(row._id, true)}></i>
                        <i className="bi bi-x-lg" style={{fontSize: '1.4rem', cursor: 'pointer'}} onClick={() => {manageExtracurricularActivityInscription(row._id, false)}}></i>
                    </>
                :
                    <>
                        {
                            row.accepted
                            ?
                                <i className="bi bi-journal-x px-2" style={{fontSize: '1.4rem', cursor: 'pointer'}} title='Cancelar suscripción' onClick={() => handleExtracurricularActivityInscriptionCancel(row.stripeId, row.alternativePayment)}/>
                            :
                                'Denegada'
                        }
                    </>
        }
    ];

    useEffect(() => {
        if (categories) {
            setCategories(categories)
        }
    }, [categories])

    useEffect(()=>{
        let optionsToPut = []
        currentCategories.map((category) => (
            optionsToPut.push({ value: category._id, label: category?.name })
        ))
        if (categoryCreated?.category) {
            optionsToPut.push({ value: categoryCreated.category._id, label: categoryCreated.category?.name })
            setCategories([...currentCategories, categoryCreated.category])
            // options.push({ value: categoryCreated.category._id, label: categoryCreated.category.name })
            setSelectedOptions([...selectedOptions, { value: categoryCreated.category._id, label: categoryCreated.category?.name }])
            // handleChange(options.find(option => option.value === categoryCreated.category._id))
            closeModalBtn.current.click()
            setCategoryName('')
            setCategoryDescription('')
            dispatch({type: CATEGORIE_CREATE_RESET})
        }
        setOptions(optionsToPut)
    }, [currentCategories, categoryCreated, selectedOptions, dispatch])

    useEffect(() => {
        let arrayValores = []
        if(extracurricularActivity?.category) {
            extracurricularActivity.category.map((extracurricularActivityCategory) => (
                arrayValores.push({ value: extracurricularActivityCategory._id, label: extracurricularActivityCategory?.name })
            ))
            setSelectedOptions(arrayValores)
            if (options.length < 1) {
                setOptions(arrayValores)
            }
        }
        let extracurricularActivityCourses = []
        if (extracurricularActivity?.courses) {
            extracurricularActivity.courses.map((extracurricularActivityCourse) => (
                extracurricularActivityCourses.push({value: extracurricularActivityCourse._id, label: extracurricularActivityCourse?.name})                
            ))
            setSelectedCourses(extracurricularActivityCourses)
            if (coursesOptions.length < 1) {
                setCoursesOptions(extracurricularActivityCourses)
            }
        }

        let extracurricularActivityStudents = []
        if (extracurricularActivity?.students) {
            extracurricularActivity.students.map((extracurricularActivityStudent) => (
                extracurricularActivityStudents.push({value: extracurricularActivityStudent._id, label: extracurricularActivityStudent.name})                
            ))
            setSelectedStudents(extracurricularActivityStudents)
            if (studentsOptions.length < 1) {
                setStudentsOptions(extracurricularActivityStudents)
            }
        }
    }, [extracurricularActivity])

    useEffect(() => {
        if (successUpdate) {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Actividad extraescolar editada correctamente',
                showConfirmButton: false,
                timer: 1500
            })
        }

        if (!extracurricularActivity || extracurricularActivity._id !== extracurricularActivityId || successUpdate) {
            dispatch({ type: EXTRACURRICULARACTIVITY_UPDATE_RESET });
            dispatch(detailsExtracurricularActivity(extracurricularActivityId));
        }
        else {
            setName(extracurricularActivity.name);
            setSku(extracurricularActivity.sku || '');
            setImage(extracurricularActivity.image);
            setDescription(extracurricularActivity.description);
            setActive(extracurricularActivity.active)
            setFees(extracurricularActivity.fees)
            setBillable(extracurricularActivity.billable ? extracurricularActivity.billable : false)
            if (extracurricularActivity.cancelAt) {
                const cancelDate = new Date(extracurricularActivity.cancelAt)
                setCancelAt(`${cancelDate.getFullYear()}-${parseInt(cancelDate.getMonth()) + 1 < 10 ? '0' + (parseInt(cancelDate.getMonth()) + 1) : parseInt(cancelDate.getMonth()) + 1}-${cancelDate.getDate() < 10 ? '0' + cancelDate.getDate() : cancelDate.getDate()}`)
            } else {
                setCancelAt(null)
            }
            if (extracurricularActivity.startAt) {
                const startDate = new Date(extracurricularActivity.startAt)
                setStartAt(`${startDate.getFullYear()}-${parseInt(startDate.getMonth()) + 1 < 10 ? '0' + (parseInt(startDate.getMonth()) + 1) : parseInt(startDate.getMonth()) + 1}-${startDate.getDate() < 10 ? '0' + startDate.getDate() : startDate.getDate()}`)
            } else {
                setStartAt(null)
            }

            setRequiresAuthorization(extracurricularActivity.requiresAuthorization || false)
            setAuthorizationText(extracurricularActivity.authorizationText || null)
            setCatalogMode(extracurricularActivity?.catalogMode ? extracurricularActivity.catalogMode : false)
            setAlternativePayment(extracurricularActivity?.alternativePayment ? extracurricularActivity.alternativePayment : false)
            dispatch(getSalesExtracurricularActivity(extracurricularActivityId))
        }
        dispatch(listCategories());
        dispatch(listCourses())
        dispatch(listStudents())
        dispatch(getNotificationsUser());
    }, [extracurricularActivity, dispatch, extracurricularActivityId, successUpdate, navigate])

    //When courses are loaded
    useEffect(() => {
        if (courses) {
            let coursesOp = []
            courses.forEach(course => {
                coursesOp.push({value: course._id, label: course.name})
            })
            setCoursesOptions(coursesOp)
        }
    }, [courses])

    //When students are loaded
    useEffect(() => {
        if (students) {
            let studentsOp = []
            students.forEach(student => {
                studentsOp.push({value: student._id, label: student.name})
            })
            setStudentsOptions(studentsOp)
        }
    }, [students])

    const submitHandler = (e) => {
        e.preventDefault();
        if (name !== '') {
            if (description !== '') {
                if (selectedOptions.length > 0) {
                    if (!requiresAuthorization || (authorizationText && authorizationText?.trim() !== '')) {
                        if (fees.filter(f => f.name !== null && f.name !== '').length > 0) {
                            for (const category of currentCategories) {
                                for (const selectedOption of selectedOptions) {
                                    if (category._id === selectedOption?.value) {
                                        selectedCategories.push(category)
                                    }
                                }
                            }
                    
                            let extracurricularActivityCourses = []
                            
                            for (const course of selectedCourses) {
                                extracurricularActivityCourses.push(course.value)
                            }

                            let extracurricularActivityStudents = []
                            
                            for (const student of selectedStudents) {
                                extracurricularActivityStudents.push(student.value)
                            }
                    
                            dispatch(updateExtracurricularActivity({
                                _id: extracurricularActivityId,
                                name,
                                sku,
                                image,
                                category: selectedCategories,
                                description,
                                active: active === "true" || active === true ? true : false,
                                courses: extracurricularActivityCourses,
                                students: extracurricularActivityStudents,
                                fees: fees,
                                billable: billable,
                                cancelAt: cancelAt === null || cancelAt === '' ? null : new Date(cancelAt),
                                startAt: startAt === null || startAt === '' ? null : new Date(startAt),
                                catalogMode,
                                requiresAuthorization,
                                authorizationText,
                            }))
                        } else {
                            Swal.fire({
                                position: 'center',
                                icon: 'error',
                                title: 'Cree al menos un precio para la actividad extraescolar',
                                showConfirmButton: true,
                                confirmButtonText: 'De acuerdo'
                            })
                        }
                    } else {
                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: 'Introduzca un texto para la autorización',
                            showConfirmButton: true,
                            confirmButtonText: 'De acuerdo'
                        })
                    }
                } else {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Seleccione al menos una categoría para la actividad extraescolar',
                        showConfirmButton: true,
                        confirmButtonText: 'De acuerdo'
                    })
                }
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Introduzca una descripción a la actividad extraescolar',
                    showConfirmButton: true,
                    confirmButtonText: 'De acuerdo'
                })
            }
        } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Introduzca un nombre a la actividad extraescolar',
                showConfirmButton: true,
                confirmButtonText: 'De acuerdo'
            })
        }
    }
    //Submit de formulario de crear categoria
    const submitCategoryHandler = (e) => {
        e.preventDefault()
        dispatch(createCategory({
            name: categoryName,
            description: categoryDescription,
            show: categoryShow,
        }))
    }

    const [loadingUpload, setLoadingUpload] = useState(false);
    const [errorUpload, setErrorUpload] = useState('');

    const userSignin = useSelector(state => state.userSignin);
    const { userInfo } = userSignin;

    const uploadFileHandler = async (e) => {
        const file = e.target.files[0];
        const bodyFormData = new FormData();
        bodyFormData.append('image', file);
        setLoadingUpload(true);
        try {
            const { data } = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/uploads/s3', bodyFormData, {
                headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${userInfo.token}` }
            })
            setImage(data);
            setLoadingUpload(false)
        } catch (error) {
            setErrorUpload(error.message);
            setLoadingUpload(false);
        }
    }

    const handleChange = (selectedOptions) => {
        setSelectedOptions(selectedOptions)
    }

    const handleCoursesChange = (selectedValues) => {
        setSelectedCourses(selectedValues)
    }

    const handleStudentsChange = (selectedValues) => {
        setSelectedStudents(selectedValues)
    }

    const handleBillableChange = (e) => {
        setBillable(e.target.checked)
    }

    useEffect(() => {
        if (salesExtracurricularActivity?.sales) {
            setSubscriptionsFiltered(salesExtracurricularActivity?.sales)
        }
    }, [salesExtracurricularActivity?.sales])

    useEffect(() => {
        let subscriptionsCopy = salesExtracurricularActivity?.sales
        if (minDate.trim() !== '') {
          subscriptionsCopy = subscriptionsCopy?.filter(o => new Date(o.paidAt) >= new Date(minDate))
        }
        if (maxDate.trim() !== '') {
          let dateObj = new Date(maxDate)
          dateObj.setDate(dateObj.getDate() + 1)
          subscriptionsCopy = subscriptionsCopy?.filter(o => new Date(o.paidAt) <= dateObj)
        }
        setSubscriptionsFiltered(subscriptionsCopy || [])
    }, [minDate, maxDate, salesExtracurricularActivity?.sales])

    useEffect(() => {
        if (success) {
            dispatch({type: EXTRACURRICULARACTIVITYINSCRIPTION_MANAGE_RESET})
            dispatch(getSalesExtracurricularActivity(extracurricularActivityId))
        }
    }, [success, dispatch, extracurricularActivityId])

    useEffect(() => {
        console.log(successCancel);
        if (successCancel) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Matricula cancelada correctamente',
            showConfirmButton: false,
            timer: 1500
          })
          dispatch({type: EXTRACURRICULARACTIVITYINSCRIPTION_CANCEL_RESET})
          dispatch(getSalesExtracurricularActivity(extracurricularActivityId))
          dispatch(getNotificationsUser());
        }
      }, [dispatch, successCancel, extracurricularActivityId])

    const handleFeeChange = (ind, e) => {
    setFees(fees.map((fee, i) => {
            if (i === ind) {
                fee[e.target.name] = e.target.value
            }
            return fee
        }))
    }

    const handleFeeCheck = (index) => {
        let newAttrValues = fees.map((fee, ind) => {
            if (ind === index) {
                return {...fee, active: !fee.active}
            } else {
                return fee
            }
        })
        setFees(newAttrValues)
    }

    const removeFee = (ind) => {
        let feesCopy = [...fees]
        feesCopy.splice(ind, 1)
        setFees(feesCopy)
    }

    const manageExtracurricularActivityInscription = (id, state) => {
        dispatch(manageInscription(id, state))
    }

    const handleExtracurricularActivityInscriptionCancel = (stripeId, alternativePayment) => {
        Swal.fire({
          title: `¿Desea cancelar la matrícula a  esta actividad extraescolar?`,
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: 'Si',
          denyButtonText: 'No', 
          cancelButtonText: "Cancelar",
          icon: "warning",
        }).then((result) => {
          if (result.isConfirmed) {
            dispatch(cancelExtracurricularActivityInscription(stripeId, alternativePayment))
          }
        })
    }

    const handleAlternativePaymentChange = (e) => {
        setAlternativePayment(e.target.checked)
    }

    return (
        <>
            <ToolBar titulo={"Editar actividad extraescolar"} hitos={[
                { titulo: "Inicio", enlace: "/" },
                { titulo: "Actividades extraescolares", enlace: "/extracurricularactivitylist" },
                { titulo: name, enlace: "#" }]} />
            <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
                <div className="content flex-row-fluid" id="kt_content">
                    {loadingUpdate && (
                        <LoadingBox variant={"primary"} />
                    )}

                    {errorUpdate && (
                        <AlertMessage variant={"danger"} message={errorUpdate} />
                    )}

                    {(loading || loadingCancel) ? (
                        <LoadingBox variant={"primary"} />
                    ) :
                        error ? (
                            <AlertMessage variant={"danger"} message={error} />
                        ) :
                            <>
                            <form autoComplete='off' id="kt_ecommerce_add_extracurricularActivity_form" className="form d-flex flex-column flex-lg-row" onSubmit={userInfo.isAdmin ? submitHandler : (e)=>{e.preventDefault()}}>

                                    <div className="d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10">
                                        <div className="card card-flush py-4">
                                            <div className="card-header">
                                                <div className="card-title">
                                                    <h2>Miniatura</h2>
                                                </div>
                                            </div>
                                            <div className="card-body text-center pt-0">
                                                <div className="image-input image-input-empty image-input-outline mb-3" data-kt-image-input="true">
                                                    <div className="image-input-wrapper w-150px h-150px" style={{ backgroundImage: `url('${image || extracurricularActivity.image}')`, backgroundPosition: 'center', backgroundSize: 'contain' }} />
                                                    <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="cancel" data-bs-toggle="tooltip" title="Cancel avatar">
                                                        <i className="bi bi-x fs-2" />
                                                    </span>
                                                    <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="remove" data-bs-toggle="tooltip" title="Remove avatar">
                                                        <i className="bi bi-x fs-2" />
                                                    </span>
                                                </div>
                                                <div className="text-muted fs-7">Establezca la imagen en miniatura de la actividad extraescolar. Solo se aceptan archivos de imagen *.png, *.jpg y *.jpeg</div>
                                            </div>
                                        </div>
                                        <div className="card card-flush py-4">
                                            <div className="card-header">
                                                <div className="card-title">
                                                    <h2>Estado</h2>
                                                </div>
                                                <div className="card-toolbar">
                                                    <div className={`rounded-circle ${active ? 'bg-success' : 'bg-danger'} w-15px h-15px`} id="kt_ecommerce_add_extracurricularActivity_status" />
                                                </div>
                                            </div>
                                            <div className="card-body pt-0">
                                            <select className="form-select mb-2" data-control="select2" data-hide-search="true" data-placeholder="Select an option" id="kt_ecommerce_add_extracurricularActivity_status_select" value={active?.toString() || ''} onChange={userInfo.isAdmin ? (e) => setActive(e.target.value) : ()=>{}}>
                                                    <option value={true}>Activado</option>
                                                    <option value={false}>Desactivado</option>
                                                </select>
                                                <div className="text-muted fs-7">Cambiar estado de actividad extraescolar.</div>
                                            </div>
                                        </div>
                                        <div className="card card-flush py-4">
                                            <div className="card-header">
                                                <div className="card-title">
                                                    <h2>Modo catálogo</h2>
                                                </div>
                                                <div className="card-toolbar">
                                                    <div className={`rounded-circle ${catalogMode ? 'bg-success' : 'bg-danger'} w-15px h-15px`} />
                                                </div>
                                            </div>
                                            <div className="card-body pt-0">
                                            <select className="form-select mb-2" data-control="select2" data-hide-search="true" data-placeholder="Select an option" value={catalogMode} onChange={userInfo.isAdmin ? (e) => setCatalogMode(e.target.value) : ()=>{}}>
                                                    <option value={true}>Activado</option>
                                                    <option value={false}>Desactivado</option>
                                                </select>
                                                <div className="text-muted fs-7">Cuando esté activado, no se podrá adquirir el producto.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
                                        <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-n2">
                                            <li className="nav-item">
                                                <a className="nav-link text-active-primary pb-4 active" data-bs-toggle="tab" href="#kt_ecommerce_add_extracurricularActivity_general">Detalles</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link text-active-primary pb-4" data-bs-toggle="tab" href="#kt_ecommerce_add_extracurricularActivity_advanced">Matriculaciones</a>
                                            </li>
                                        </ul>
                                        <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
                                            <div className="tab-content">
                                                <div className="tab-pane fade show active" id="kt_ecommerce_add_extracurricularActivity_general">
                                                    <div className="d-flex flex-column gap-7 gap-lg-10">
                                                        <div className="card card-flush py-4">
                                                            <div className="card-header">
                                                                <div className="card-title">
                                                                    <h2>General</h2>
                                                                </div>
                                                            </div>
                                                            <div className="card-body pt-0">
                                                                <div className="mb-10 fv-row">
                                                                    <label className="required form-label">Nombre de la actividad extraescolar</label>
                                                                    <input required className="form-control mb-2" id="name" type="text" value={name || ''} onChange={userInfo.isAdmin ? (e) => setName(e.target.value) : ()=>{}} />
                                                                    <div className="text-muted fs-7">Se requiere un nombre de actividad extraescolar que sea único.</div>
                                                                </div>
                                                                <div className="mb-10 fv-row">
                                                                    <label className="form-label">SKU de la actividad extraescolar</label>
                                                                    <input className="form-control mb-2" id="sku" type="text" value={sku || ''} onChange={userInfo.isAdmin ? (e) => setSku(e.target.value) : ()=>{}} />
                                                                    <div className="text-muted fs-7">Introduzca una la referencia de la actividad extraescolar si lo tuviera</div>
                                                                </div>
                                                                <div className="mb-10 fv-row">
                                                                    <label className="required form-label">Descripción de la actividad extraescolar</label>
                                                                    <textarea required className="form-control mb-3" rows="5" data-kt-element="input" placeholder="Type a message" value={description || ''} onChange={userInfo.isAdmin ? (e) => setDescription(e.target.value) : ()=>{}}></textarea>
                                                                </div>
                                                                <div className="mb-10 fv-row">
                                                                    <label className="required form-label">Categoría</label>
                                                                    {loadingCategory ? (<LoadingBox variant={"primary"} />) :
                                                                        errorCategory ? (<AlertMessage variant={"danger"} message={errorCategory} />) : (
                                                                            <Select isMulti isClearable options={options} onChange={userInfo.isAdmin ? handleChange : ()=>{}} value={selectedOptions || ''} placeholder="Escoja la/s categorías de la actividad extraescolar" />
                                                                        )
                                                                    }
                                                                    <button type="button" className=" mt-5 btn btn-light-primary btn-sm mb-10" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                                                        <span className="svg-icon svg-icon-2">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                <rect opacity="0.5" x="11" y="18" width="12" height="2" rx="1" transform="rotate(-90 11 18)" fill="black"></rect>
                                                                                <rect x="6" y="11" width="12" height="2" rx="1" fill="black"></rect>
                                                                            </svg>
                                                                        </span>
                                                                        Crear nueva categoría
                                                                    </button>
                                                                </div>
                                                                {/* {
                                                                    process.env.REACT_APP_ALTERNATIVE_PAYMENT_METHOD && process.env.REACT_APP_ALTERNATIVE_PAYMENT_METHOD === 'stripe' &&
                                                                        <div className="fv-row mb-10">
                                                                            <label className='form-check form-switch form-check-custom form-check-solid'>
                                                                                <span className='form-check-label fw-bold me-4'>Pago alternativo</span>
                                                                                <input className='form-check-input' type='checkbox' checked={alternativePayment} onChange={handleAlternativePaymentChange} />
                                                                            </label>
                                                                        </div>
                                                                } */}
                                                                <div className="mb-10 fv-row">
                                                                    <label className='form-check form-switch form-check-custom form-check-solid'>
                                                                        <span className='form-check-label fw-bold me-4'>Facturable</span>
                                                                        <input className='form-check-input' type='checkbox' checked={billable} onChange={userInfo.isAdmin ? handleBillableChange : ()=>{}} />
                                                                    </label>
                                                                </div>
                                                                <div className="fv-row mb-10">
                                                                    <label className="form-label">Válido desde</label>
                                                                    <input className="form-control mb-2" id="startAt" type="date" value={startAt || ''} onChange={userInfo.isAdmin ? (e) => setStartAt(e.target.value) : ()=>{}} />
                                                                </div>
                                                                <div className="fv-row">
                                                                    <label className="form-label">Válido hasta</label>
                                                                    <input className="form-control mb-2" id="cancelAt" type="date" value={cancelAt || ''} onChange={userInfo.isAdmin ? (e) => setCancelAt(e.target.value) : ()=>{}} />
                                                                </div>
                                                                {/* <div className="mb-10 fv-row">
                                                                    <label className="required form-label">Marca</label>
                                                                    <input className="form-control mb-2" id="brand" type="text" value={brand || ''} onChange={userInfo.isAdmin ? (e) => setBrand(e.target.value) : ()=>{}} />
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                        <div className="card card-flush py-4">
                                                            <div className="card-header flex-row w-100">
                                                                <div className="card-title flex-row w-100">
                                                                    <div className='row w-100 align-items-center'>
                                                                        <div className='col-md-2 col-6'>
                                                                            <h2>Precios</h2>
                                                                            <button type='button' className='btn btn-light btn-sm' onClick={() => {setFees([...fees, {active: false, period: "day"}])}}><i className='fas fa-plus p-0'/></button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card-body pt-0">
                                                                <div className='fv-row row'>
                                                                    {fees.map((fee, ind) => (
                                                                        <div key={ind} className='col-md-4 col-12 px-2 py-4'>
                                                                            <div className='border p-4'>
                                                                                <label className='mt-3'>Nombre</label>
                                                                                <input type="text" className="form-control" value={fee.name || ''} name="name" onChange={userInfo.isActive ? (e) => {handleFeeChange(ind, e)} : ()=>{}} required/>
                                                                                <label className='mt-3'>Precio</label>
                                                                                <input type="number" className="form-control" value={fee.price || ''} name="price" onChange={userInfo.isActive ? (e) => {handleFeeChange(ind, e)} : ()=>{}} disabled={fee._id ? true : false}/>
                                                                                <label className='mt-3'>Periodicidad</label>
                                                                                <select className='form-select' name='period' value={fee.period || "day"} onChange={userInfo.isActive ? (e) => {handleFeeChange(ind, e)} : ()=>{}} required>
                                                                                    <option value="month">Mensual</option>
                                                                                    <option value="year">Anual</option>
                                                                                    <option value="week">Semanal</option>
                                                                                    <option value="day">Diario</option>
                                                                                </select>
                                                                                <label className='form-check form-switch form-check-custom form-check-solid mt-3'>
                                                                                <input className='form-check-input' type='checkbox' checked={fee.active || false} onChange={userInfo.isActive ? () => {handleFeeCheck(ind)} : ()=>{}} />
                                                                                    <span className='form-check-label fw-bold text-gray-400'>Activo</span>
                                                                                </label>
                                                                                {
                                                                                    !fee.stripeId && fees.length > 1 &&
                                                                                        <div className='d-grid gap-2 mt-5'>
                                                                                            <button type='button' className='btn btn-danger' onClick={() => {removeFee(ind)}}><i className='fas fa-trash'/></button>
                                                                                        </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card card-flush py-4">
                                                            <div className="card-header flex-row w-100">
                                                                <div className="card-title flex-row w-100">
                                                                    <div className='row w-100 align-items-center'>
                                                                        <div className='col-6'>
                                                                            <h2>Cursos relacionados</h2>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card-body pt-0">
                                                                <div className="fv-row mb-2">
                                                                {
                                                                    loadingCourses
                                                                    ?
                                                                        (<LoadingBox variant={"primary"} />)
                                                                    :
                                                                        errorCourses
                                                                        ?
                                                                            (<AlertMessage variant={"danger"} message={errorCourses} />)
                                                                        :
                                                                            (
                                                                                <Select isMulti options={coursesOptions} onChange={userInfo.isActive ? handleCoursesChange : ()=>{}} value={selectedCourses || ''}  placeholder="Escoja los cursos de la actividad extraescolar" />
                                                                            )
                                                                }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card card-flush py-4">
                                                            <div className="card-header flex-row w-100">
                                                                <div className="card-title flex-row w-100">
                                                                    <div className='row w-100 align-items-center'>
                                                                        <div className='col-6'>
                                                                            <h2>Estudiantes relacionados</h2>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card-body pt-0">
                                                                <div className="fv-row mb-2">
                                                                {
                                                                    loadingStudents
                                                                    ?
                                                                        (<LoadingBox variant={"primary"} />)
                                                                    :
                                                                        errorStudents
                                                                        ?
                                                                            (<AlertMessage variant={"danger"} message={errorStudents} />)
                                                                        :
                                                                            (
                                                                                <Select isMulti options={studentsOptions} onChange={userInfo.isAdmin ? handleStudentsChange : ()=>{}} value={selectedStudents}  placeholder="Escoja los estudiantes del producto" />
                                                                            )
                                                                }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='card card-flush py-4'>
                                                            <div className='card-header flex-row w-100'>
                                                                <div className='card-title flex-row w-100'>
                                                                    <div className='row w-100 align-items-center'>
                                                                        <div className='col-6'>
                                                                            <h2>Autorización</h2>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='card-body pt-0'>
                                                                <div className='fv-row mb-2'>
                                                                    <label className='form-check form-switch form-check-custom form-check-solid'>
                                                                        <span className='form-check-label fw-bold me-4'>Requiere autorización</span>
                                                                        <input className='form-check-input' type='checkbox' checked={requiresAuthorization} onChange={(e) => setRequiresAuthorization(e.target.checked)} />
                                                                    </label>
                                                                </div>
                                                                <div className='fv-row mb-2'>
                                                                    <label className='form-label'>Texto de autorización</label>
                                                                    <textarea className='form-control' disabled={!requiresAuthorization} value={authorizationText || ''} onChange={(e) => setAuthorizationText(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card card-flush py-4">
                                                            <div className="card card-flush py-4">
                                                                <div className="card-header">
                                                                    <div className="card-title">
                                                                        <h2>Imagen</h2>
                                                                    </div>
                                                                </div>
                                                                <div className="card-body pt-0">
                                                                    <div className="fv-row mb-2">
                                                                        <label htmlFor="imageFile" className="form-label">Eligir la imagen de la actividad extraescolar</label>
                                                                        <input className="form-control" required={false} type="file" id="imageFile" onChange={userInfo.isActive ? uploadFileHandler : ()=>{}}></input>
                                                                        {loadingUpload && <LoadingBox variant={"primary"} />}
                                                                        {errorUpload && <div>{errorUpload}</div>}
                                                                    </div>
                                                                    <div className="text-muted fs-7">Configure la galería multimedia de la actividad extraescolar.</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="kt_ecommerce_add_extracurricularActivity_advanced" role="tab-panel">
                                                    <div className="d-flex flex-column gap-7 gap-lg-10">
                                                        <div className="card card-flush py-4">
                                                            <div className="card-header">
                                                                <div className="card-title">
                                                                    <h2>Matriculaciones</h2>
                                                                </div>
                                                            </div>
                                                            <div className="card-body pt-0">
                                                                <div className='row mb-6'>
                                                                    <div className='col-6'>
                                                                        <label className='text-muted'>Fecha de comienzo</label>
                                                                        <input className='form-control' type="date" name='minDate' value={minDate || ''} onChange={userInfo.isActive ? (e) => {setMinDate(e.target.value)} : ()=>{}}/>
                                                                    </div>
                                                                    <div className='col-6'>
                                                                        <label className='text-muted'>Fecha de fin</label>
                                                                        <input className='form-control' type="date" name='maxDate' value={maxDate || ''} onChange={userInfo.isActive ? (e) => {setMaxDate(e.target.value)} : ()=>{}}/>
                                                                    </div>
                                                                </div>
                                                                <CustomDataTable data={subscriptionsFiltered} columns={columns} />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <Link to="/extracurricularactivitylist" id="kt_ecommerce_add_extracurricularActivity_cancel" className="btn btn-light me-5">Cancelar</Link>
                                                <button type="submit" id="kt_ecommerce_add_extracurricularActivity_submit" className="btn btn-primary">
                                                    <span className="indicator-label">Actualizar</span>
                                                    <span className="indicator-progress">Espere por favor...
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2" /></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                    <div className="modal-dialog modal-xl">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="staticBackdropLabel">Crear categoría</h5>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body">
                                            <form autoComplete='off' className="form w-100 row" id="kt_sign_in_form" onSubmit={userInfo.isAdmin ? submitCategoryHandler : ()=>{}}>
                                                    <div className="mb-10 fv-row">
                                                        <label className="required form-label">Nombre de la categoría</label>
                                                        <input className="form-control mb-2" id="name" type="text" value={categoryName || ''} onChange={userInfo.isActive ? (e) => setCategoryName(e.target.value) : ()=>{}} />
                                                        <div className="text-muted fs-7">Se requiere un nombre de categoría que sea único.</div>
                                                    </div>
                                                    <div className="mb-10 fv-row">
                                                        <label className="required form-label">Descripción</label>
                                                        <textarea className="form-control mb-3" rows="5" data-kt-element="input" placeholder="Type a message" value={categoryDescription || ''} onChange={userInfo.isActive ? (e) => setCategoryDescription(e.target.value) : ()=>{}}></textarea>
                                                    </div>
                                                    <div className="mb-10 fv-row">
                                                        <label className="required form-label">Estado</label>
                                                        <select className="form-select mb-2" data-control="select2" data-hide-search="true" data-placeholder="Select an option" id="kt_ecommerce_add_product_status_select" value={categoryShow} onChange={(e) => setCategoryShow(e.target.value)}>
                                                            <option value={true}>Mostrada</option>
                                                            <option value={false}>Oculta</option>
                                                        </select>
                                                        <div className="text-muted fs-7">Mostrar/Ocultar categoría.</div>
                                                    </div>
                                                    <div className="text-end">
                                                        <button type="submit" id="kt_sign_in_submit" className="btn btn-primary me-1" disabled={categoryCreated.loading ? 'disabled' : ''}>
                                                            {
                                                                categoryCreated.loading ? (
                                                                    <span className="indicator-progress">Espere por favor...
                                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                                                ) :
                                                                    (<span className="indicator-label">Crear</span>)
                                                            }
                                                        </button>
                                                        <button ref={closeModalBtn} type="button" id="closeModal" className="btn btn-secondary ms-1" data-bs-dismiss="modal">Cerrar</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                    }
                </div>
            </div>
        </>
    )
}