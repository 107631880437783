import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import { signout } from './actions/userActions';
import { CartScreen } from './screens/CartScreen';
import { MessageScreen } from './screens/MessageScreen';
import { StopbullyingScreen } from './screens/StopbullyingScreen';
import { HomeScreen } from './screens/HomeScreen';
import { OrderHistoryScreen } from './screens/OrderHistoryScreen';
import { OrderScreen } from './screens/OrderScreen';
import { PaymentMethodScreen } from './screens/PaymentMethodScreen';
import { PlaceOrderScreen } from './screens/PlaceOrderScreen';
import { ProductScreen } from './screens/ProductScreen';
import { ProfileScreen } from './screens/ProfileScreen';
import { ShippingAddressScreen } from './screens/ShippingAddressScreen';
import { SigninScreen } from './screens/SigninScreen';
import { PrivateRoute } from './components/PrivateRoute';

import { AdminRoute } from './components/AdminRoute';
import { AbsencesCourseScreen } from './screens/AbsencesCourseScreen';
import { ProductListScreen } from './screens/ProductListScreen';
import { EditProductScreen } from './screens/EditProductScreen';
import { SubscriptionProductListScreen } from './screens/SubscriptionProductListScreen';
import { EditSubscriptionProductScreen } from './screens/EditSubscriptionProductScreen';
import { OrderListScreen } from './screens/OrderListScreen';
import { UserListScreen } from './screens/UserListScreen';
import { UserEditScreen } from './screens/UserEditScreen';
import { PushNotificationScreeen } from './screens/PushNotificationScreeen';
import { getUserToken } from './firebase';
import { OrderConfirmation } from './screens/OrderConfirmation';
import { CategoryListScreen } from './screens/CategoryListScreen';
import { EditCategoryScreen } from './screens/EditCategoryScreen';
import { LoadingBox } from './components/LoadingBox';
import { AlertMessage } from './components/AlertMessage';
import { listCategories } from './actions/categoryActions';
import { CategoryScreen } from './screens/CategoryScreen';
import { CourseListScreen } from './screens/CourseListScreen';
import { CourseMessageScreen } from './screens/CourseMessageScreen';
import { StudentMessageScreen } from './screens/StudentMessageScreen';
import { EditCourseScreen } from './screens/EditCourseScreen';
import { CreateProductScreen } from './screens/CreateProductScreen';
import { CreateSubscriptionProductScreen } from './screens/CreateSubscriptionProductScreen';
import { CreateCategoryScreen } from './screens/CreateCategoryScreen';
import { CreateCourseScreen } from './screens/CreateCourseScreen';
import { StudentListScreen } from './screens/StudentListScreen';
import { StudentEditScreen } from './screens/StudentEditScreen';
import { AttributeListScreen } from './screens/AttributeListScreen';
import { CreateAttributeScreen } from './screens/CreateAttributeScreen';
import { EditAttributeScreen } from './screens/EditAttributeScreen';
import { listAttributes } from './actions/attributeActions';
import { PublicRoute } from './components/PublicRoute';
import { ForgotPasswordScreen } from './screens/ForgotPasswordScreen';
import { ResetPasswordScreen } from './screens/ResetPasswordScreen';
import { getNotificationsUser, readNotification, registerDevice } from './actions/notificationActions';
import { ImportScreeen } from './screens/ImportScreen';
import { OrderPaidScreen } from './screens/OrderPaidScreen';
import { PayOrderScreen } from './screens/PayOrderScreen';
import { detailsOrder } from './actions/orderActions';
import { CART_EMPTY } from './constants/cartConstants';
import { DownloadOrderScreen } from './screens/DownloadOrderScreen';
import { InvoiceScreen } from './screens/InvoiceScreen';
import { OrdersNotPaidListScreen } from './screens/OrdersNotPaidListScreen';
import { SubscriptionProductScreen } from './screens/SubscriptionProductScreen';
import { SubscriptionHistoryScreen } from './screens/SubscriptionHistoryScreen';
import { SubscriptionListScreen } from './screens/SubscriptionListScreen';
import { ExtracurricularActivityListScreen } from './screens/ExtracurricularActivityListScreen';
import { CreateExtracurricularActivityScreen } from './screens/CreateExtracurricularActivityScreen';
import { EditExtracurricularActivityScreen } from './screens/EditExtracurricularActivityScreen';
import { ExtracurricularActivityScreen } from './screens/ExtracurricularActivityScreen';
import { ExtracurricularActivityHistoryScreen } from './screens/ExtracurricularActivityHistoryScreen';
import { ExtracurricularActivityInscriptionScreen } from './screens/ExtracurricularActivityInscriptionScreen';
import { PaymentMethodListScreen } from './screens/PaymentMethodListScreen';
import { ConsultantRoute } from './components/ConsultantRoute';
import { ExtracurricularActivityMessageScreen } from './screens/ExtracurricularActivityMessageScreen';
import { sendLog } from './actions/logActions';
import { DeleteOrderSelectionScreen } from './screens/DeleteOrderSelectionScreen';

/* UNCOMMENT IF CREATING ANDROID/IOS APP */
// import { PushNotifications} from '@capacitor/push-notifications';

function App() {
    const REACT_APP = process.env.REACT_APP_TYPE
    const innerWidth = window.innerWidth
    const innerHeight = window.innerHeight
    const [currentCategories, setCategories] = useState([]);
    const [css, setCss] = useState({})
    const [contentCss, setContentCss] = useState({})

    const cart = useSelector(state => state.cart);
    const { cartItems } = cart;

    const userSignin = useSelector((state) => state.userSignin);
    const { userInfo } = userSignin

    const orderDetails = useSelector((state) => state.orderDetails);

    const dispatch = useDispatch();

    const categorieList = useSelector((state) => state.categorieList);
    const { loading, error, categories } = categorieList;

    const attributeList = useSelector((state) => state.attributeList);
    const { attributes } = attributeList;

    const categoryCreated = useSelector(state => state.categoryCreate)

    const notifications = useSelector(state => state.notifications)

    useEffect(() => {
        dispatch(listCategories());
        dispatch(getNotificationsUser());
        dispatch(listAttributes())
        if (localStorage.getItem('orderId')) {
            dispatch(detailsOrder(localStorage.getItem('orderId')))
        }

        if (localStorage.getItem('SDerrorlog') !== undefined && localStorage.getItem('SDerrorlog') !== null && localStorage.getItem('SDerrorlog') !== ''){
            dispatch(sendLog(localStorage.getItem('SDerrorlog')))
        }else{
            localStorage.setItem('SDerrorlog', '')
        }
}, [dispatch])

    useEffect(() => {
        if (categories) {
            setCategories(categories)
        }
    }, [categories])

    useEffect(() => {
        if (orderDetails.order?.isPaid) {
            dispatch({ type: CART_EMPTY });
            localStorage.removeItem('cartItems');
            localStorage.removeItem('orderId');
        }
    }, [orderDetails.order, dispatch])

    //Cuando se haya creado una categoria
    useEffect(() => {
        if (categoryCreated?.category) {
            setCategories([...currentCategories, categoryCreated.category])
        }
    }, [categoryCreated, currentCategories])

    const signoutHandler = () => {
        dispatch(signout());
    }

    const viewNotification = (id) => {
        dispatch(readNotification(id))
    }

    const viewAllNotifications = (e) => {
        e.preventDefault()
        notifications.map(notification => (
            dispatch(readNotification(notification._id))
        ))
    }

    const printChildrenCategories = (category, index) => {
        return (
                category.show && (
                currentCategories?.find(c => c.parent === category._id)
                ?
                    <div className="menu-item submenu-item here menu-lg-down-accordion me-lg-1" data-kt-menu-trigger="click" data-kt-menu-placement="right-start">
                        <span className='menu-link submenu-link'>
                            <span className='menu-title' style={{paddingTop: '0.15rem', paddingBottom: '0.15rem'}}>
                                <Link className='menu-title' to={`/category/${category._id}`}>{category.name}</Link>
                            </span>
                        </span>
                        <div className='menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-rounded-0 m-lg-4 w-lg-225px'>
                            {
                                currentCategories?.filter(c => c.parent === category._id)?.map( (c, i) => (
                                    <Fragment key={String(index) + String(i)}>
                                        {printChildrenCategories(c, String(index) + String(i))}
                                    </Fragment>
                                ))
                            }
                        </div>
                    </div>
                :
                <div className="menu-item submenu-item here menu-lg-down-accordion">
                    <Link to={`/category/${category._id}`} className="menu-title">
                        <span className="menu-link submenu-link py-3" >
                            {category.name}
                        </span>
                    </Link>
                </div>
            )
        )
    }

    if (userInfo) {
        if (REACT_APP !== 'WEB') {
            /* UNCOMMENT IF CREATING ANDROID/IOS APP */
            // PushNotifications.checkPermissions().then((res) => {
            //         if (res.receive !== 'granted') {
            //                 PushNotifications.requestPermissions().then((res) => {
            //                         if (res.receive !== 'denied') {
            //                                 register()
            //                         }
            //                 })
            //         } else {
            //                 register()
            //         }
            // })
        }
        try {
            getUserToken()
        } catch (error) { 
        }
    }

/* UNCOMMENT IF CREATING ANDROID/IOS APP */
// const register = () => {
//     // Register with Apple / Google to receive push via APNS/FCM
//     PushNotifications.register()
//     // On success, we should be able to receive notifications
//     PushNotifications.addListener('registration',
//             (token) => {
//                     dispatch(registerDevice(token.value, userInfo._id))
//             }
//     );

//     // Some issue with our setup and push will not work
//     PushNotifications.addListener('registrationError',
//             (error) => {
//                     alert('Error on registration: ' + JSON.stringify(error));
//             }
//     );

//     // Show us the notification payload if the app is open on our device
//     PushNotifications.addListener('pushNotificationReceived',
//             (notification) => {
//                 dispatch(getNotificationsUser());
//             }
//     );

//     // Method called when tapping on a notification
//     PushNotifications.addListener('pushNotificationActionPerformed',
//             (notification) => {
//                 dispatch(getNotificationsUser());
//             }
//     );
// }
/* UNCOMMENT IF CREATING IOS APP */
// useEffect(() => {
//     if (innerHeight > innerWidth && innerWidth / innerHeight < 0.5625) {
//         setCss({
//             height: 'fit-content',
//             paddingTop: '80px',
//             paddingBottom: '1rem',
//         })
//         setContentCss({
//             paddingTop: '4.6rem'
//         })
//     } else {
//         setCss({})
//         setContentCss({})
//     }
// }, [innerHeight, innerWidth])

    return (
        <>
            <BrowserRouter>
                <div className="page d-flex flex-row flex-column-fluid">
                    <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                        <div id="kt_header" className="header align-items-stretch bg-white" data-kt-sticky="true" data-kt-sticky-name="header" data-kt-sticky-offset="{default: '200px', lg: '300px'}" style={{height: 'fit-content', paddingBottom: '1rem'}}>
                            <div className="container-xxl d-flex align-items-center mobile-menu" style={css}>
                                <div className="d-flex topbar align-items-center d-lg-none ms-n2 me-3" title="Show aside menu">
                                    <div className="btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px" id="kt_header_menu_mobile_toggle">
                                        <span className="svg-icon svg-icon-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="black" />
                                                <path opacity="0.3" d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="black" />
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                                <div className="header-logo me-5 me-md-10 flex-grow-1 flex-lg-grow-0">
                                    <Link to={"/"}>
                                        <img alt="Logo" src="/assets/media/logos/Grupo_185.jpg" className="logo-default h-50px" />
                                        <img alt="Logo" src="/assets/media/logos/Grupo_185.jpg" className="logo-sticky h-50px" />
                                    </Link>
                                </div>
                                <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
                                    <div className="d-flex align-items-stretch" id="kt_header_nav">
                                        <div className="header-menu align-items-stretch mobile-menu-content" data-kt-drawer="true" data-kt-drawer-name="header-menu" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'200px', '300px': '250px'}" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_header_menu_mobile_toggle" data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}" style={contentCss}>
                                            <div className="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch" id="#kt_header_menu" data-kt-menu="true">
                                                {
                                                    userInfo
                                                    ?
                                                    <>
                                                        <div className="menu-item here show menu-lg-down-accordion me-lg-1">
                                                            <Link to="/home" className="menu-title">
                                                                <span className="menu-link py-3 text-white">Inicio</span>
                                                            </Link>
                                                        </div>
                                                        {loading ? (<LoadingBox variant={"primary"} />) :
                                                            error ? (<AlertMessage variant={"danger"} message={error} />) : (
                                                                currentCategories.map((category, index) => (
                                                                    category.show && !currentCategories.find(c => c.show === true && c._id === category.parent) && (
                                                                        currentCategories?.find(c => c.parent === category._id)
                                                                        ?
                                                                            <div className="menu-item here menu-lg-down-accordion me-lg-1" key={category._id} data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start">
                                                                                <span className='menu-link'>
                                                                                    <span className='menu-title' style={{paddingTop: '0.15rem', paddingBottom: '0.15rem'}}>
                                                                                        <Link className='text-white' to={`/category/${category._id}`}>{category.name}</Link>
                                                                                    </span>
                                                                                </span>
                                                                                <div className='menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-rounded-0 m-lg-4 w-lg-225px'>
                                                                                    {
                                                                                        currentCategories?.filter(c => c.parent === category._id)?.map( (c, i) => (
                                                                                            <Fragment key={String(index) + String(i)}>
                                                                                                {printChildrenCategories(c, String(index) + String(i))}
                                                                                            </Fragment>
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        :
                                                                        <div className="menu-item here menu-lg-down-accordion me-lg-1" key={category._id}>
                                                                            <Link to={`/category/${category._id}`} className="menu-title">
                                                                                <span className="menu-link py-3 text-white" >
                                                                                    {category.name}
                                                                                </span>
                                                                            </Link>
                                                                        </div>
                                                                    )
                                                                ))
                                                        )}
                                                    </>
                                                    :
                                                        <></>
                                                }
                                            </div>

                                        </div>
                                    </div>
                                    <div className="topbar d-flex align-items-stretch flex-shrink-0">
                                        <div className='d-flex align-items-center ms-1 ms-lg-3'>
                                            <div className='btn btn-icon btn-active-light-primary position-relative w-30px h-30px w-md-40px h-md-40px' id='kt_activities_toggle_two'>
                                                <span className="svg-icon svg-icon-1">
                                                    <Link to={"/messages"}>
                                                        <svg  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path opacity="0.3" d="M21 18H3C2.4 18 2 17.6 2 17V7C2 6.4 2.4 6 3 6H21C21.6 6 22 6.4 22 7V17C22 17.6 21.6 18 21 18Z" fill="black"/>
                                                            <path d="M11.4 13.5C11.8 13.8 12.3 13.8 12.6 13.5L21.6 6.30005C21.4 6.10005 21.2 6 20.9 6H2.99998C2.69998 6 2.49999 6.10005 2.29999 6.30005L11.4 13.5Z" fill="black"/>
                                                        </svg>
                                                    </Link>
                                                </span>
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-center ms-1 ms-lg-3'>
                                            <div className='btn btn-icon btn-active-light-primary position-relative w-30px h-30px w-md-40px h-md-40px' id='kt_activities_toggle'>
                                                <span className="svg-icon svg-icon-1">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px">
                                                        <path opacity="0.3" d="M20 3H4C2.89543 3 2 3.89543 2 5V16C2 17.1046 2.89543 18 4 18H4.5C5.05228 18 5.5 18.4477 5.5 19V21.5052C5.5 22.1441 6.21212 22.5253 6.74376 22.1708L11.4885 19.0077C12.4741 18.3506 13.6321 18 14.8167 18H20C21.1046 18 22 17.1046 22 16V5C22 3.89543 21.1046 3 20 3Z" fill="black"></path>
                                                        <rect x="6" y="12" width="7" height="2" rx="1" fill="black"></rect>
                                                        <rect x="6" y="7" width="12" height="2" rx="1" fill="black"></rect>
                                                    </svg>
                                                </span>
                                                {notifications.filter(noti => noti.read === false).length > 0
                                                    ?
                                                    <span className="bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink"></span>
                                                    :
                                                        <></>
                                                }
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center ms-1 ms-lg-3">
                                            <div className="btn btn-icon btn-active-light-primary position-relative btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px" id="kt_drawer_chat_toggle">
                                                <span className="svg-icon svg-icon-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <path opacity="0.3" d="M18.041 22.041C18.5932 22.041 19.041 21.5932 19.041 21.041C19.041 20.4887 18.5932 20.041 18.041 20.041C17.4887 20.041 17.041 20.4887 17.041 21.041C17.041 21.5932 17.4887 22.041 18.041 22.041Z" fill="black"></path>
                                                        <path opacity="0.3" d="M6.04095 22.041C6.59324 22.041 7.04095 21.5932 7.04095 21.041C7.04095 20.4887 6.59324 20.041 6.04095 20.041C5.48867 20.041 5.04095 20.4887 5.04095 21.041C5.04095 21.5932 5.48867 22.041 6.04095 22.041Z" fill="black"></path>
                                                        <path opacity="0.3" d="M7.04095 16.041L19.1409 15.1409C19.7409 15.1409 20.141 14.7409 20.341 14.1409L21.7409 8.34094C21.9409 7.64094 21.4409 7.04095 20.7409 7.04095H5.44095L7.04095 16.041Z" fill="black"></path>
                                                        <path d="M19.041 20.041H5.04096C4.74096 20.041 4.34095 19.841 4.14095 19.541C3.94095 19.241 3.94095 18.841 4.14095 18.541L6.04096 14.841L4.14095 4.64095L2.54096 3.84096C2.04096 3.64096 1.84095 3.04097 2.14095 2.54097C2.34095 2.04097 2.94096 1.84095 3.44096 2.14095L5.44096 3.14095C5.74096 3.24095 5.94096 3.54096 5.94096 3.84096L7.94096 14.841C7.94096 15.041 7.94095 15.241 7.84095 15.441L6.54096 18.041H19.041C19.641 18.041 20.041 18.441 20.041 19.041C20.041 19.641 19.641 20.041 19.041 20.041Z" fill="black"></path>
                                                    </svg>
                                                    {cartItems.length > 0 && (
                                                        <span className="fs-8 opacity-75">{cartItems.length}</span>
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center me-n3 ms-1 ms-lg-3" id="kt_header_user_menu_toggle">
                                            <div className="btn btn-icon btn-active-light-primary btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px" data-kt-menu-trigger="click" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
                                                {userInfo?.image ?
                                                    <img className="h-30px w-30px rounded" src={userInfo.image} alt='avatar'/>
                                                :
                                                    <img className="h-30px w-30px rounded" src="/assets/media/avatars/blank.png" alt="avatar" />
                                                }
                                            </div>
                                            <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px" data-kt-menu="true">

                                                {
                                                    userInfo ? (
                                                        <>
                                                            <div className="menu-item px-3">
                                                                <div className="menu-content d-flex align-items-center px-3">
                                                                    <div className="symbol symbol-50px me-5">
                                                                        {userInfo.image ?
                                                                            <img src={userInfo.image} alt='avatar'/>
                                                                        :
                                                                            <img src="/assets/media/avatars/blank.png" alt="avatar" />
                                                                        }
                                                                    </div>
                                                                    <div className="d-flex flex-column">
                                                                        <div className="fw-bolder d-flex align-items-center fs-5">{userInfo.name}</div>
                                                                        <div className="fw-bold text-muted fs-7">{userInfo.email}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="separator my-2"></div>

                                                            {
                                                                userInfo && (userInfo.isAdmin || userInfo.isTeacher || userInfo.isStopbullying || userInfo.isConsultant) && (
                                                                    <div className="menu-item px-5" data-kt-menu-trigger="hover" data-kt-menu-placement="left-start">
                                                                        <span className="menu-link px-5">
                                                                            <span className="menu-arrow-left"></span>
                                                                            <span className="menu-title">Administración</span>
                                                                            
                                                                        </span>
                                                                        <div className="menu-sub menu-sub-dropdown w-175px py-4">
                                                                            {
                                                                                (userInfo.isAdmin || userInfo.isTeacher) &&
                                                                                    <div className="menu-item px-5" data-kt-menu-trigger="hover" data-kt-menu-placement="left-start">
                                                                                        <span className="menu-link px-5">
                                                                                            <span className="menu-arrow-left"></span>
                                                                                            <span className="menu-title">Colegio</span>                                                                                            
                                                                                        </span>
                                                                                        <div className="menu-sub menu-sub-dropdown w-175px py-4">
                                                                                            <div className="menu-item px-3">
                                                                                                <Link to="/courselist" className="menu-link px-5">Cursos</Link>
                                                                                            </div>
                                                                                            <div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="left-start">
                                                                                                <span className="menu-link px-5">
                                                                                                    <span className="menu-arrow-left"></span>
                                                                                                    <span className="menu-title">Mensajería</span>                                                                                                    
                                                                                                </span>
                                                                                                <div className="menu-sub menu-sub-dropdown w-175px py-4">
                                                                                                    <div className="menu-item px-3">
                                                                                                        <Link to="/coursenotification" className="menu-link px-5">Cursos</Link>
                                                                                                    </div>
                                                                                                    <div className="menu-item px-3">
                                                                                                        <Link to="/extracurricularactivitynotification" className="menu-link px-5">Actividades extraescolares</Link>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="menu-item px-3">
                                                                                                <Link to="/stopbullying" className="menu-link px-5">No al Bullying</Link>
                                                                                            </div>
                                                                                            <div className="menu-item px-3">
                                                                                                <Link to="/import" className="menu-link px-5">Importador</Link>
                                                                                            </div>
                                                                                            {
                                                                                                userInfo.isAdmin &&
                                                                                                <>
                                                                                                    <div className="menu-item px-3">
                                                                                                        <Link to="/userlist" className="menu-link px-5">Padres</Link>
                                                                                                    </div>
                                                                                                    <div className="menu-item px-3">
                                                                                                        <Link to="/studentlist" className="menu-link px-5">Alumnos</Link>
                                                                                                    </div>
                                                                                                    <div className="menu-item px-3">
                                                                                                        <Link to="/pushnotification" className="menu-link px-5">Notificaciones</Link>
                                                                                                    </div>                                                                                                    
                                                                                                </>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                            }
                                                                            {
                                                                                (userInfo.isAdmin|| userInfo.isConsultant)  &&
                                                                                    <div className="menu-item px-5" data-kt-menu-trigger="hover" data-kt-menu-placement="left-start">
                                                                                        <span className="menu-link px-5">
                                                                                            <span className="menu-arrow-left"></span>
                                                                                            <span className="menu-title">Tienda</span>                                                                                            
                                                                                        </span>
                                                                                        <div className="menu-sub menu-sub-dropdown w-175px py-4">
                                                                                            {
                                                                                                userInfo.isAdmin &&
                                                                                                    <div className="menu-item px-3">
                                                                                                        <Link to="/categorylist" className="menu-link px-5">Categorías</Link>
                                                                                                    </div>
                                                                                            }
                                                                                            <div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="left-start">
                                                                                                <span className="menu-link px-5">
                                                                                                    <span className="menu-arrow-left"></span>
                                                                                                    <span className="menu-title">Productos</span>
                                                                                                </span>
                                                                                                <div className="menu-sub menu-sub-dropdown w-175px py-4">
                                                                                                    {
                                                                                                        userInfo.isAdmin &&
                                                                                                            <div className="menu-item px-3">
                                                                                                                <Link to="attributelist" className="menu-link px-5">Atributos</Link>
                                                                                                            </div>
                                                                                                    }
                                                                                                    <div className="menu-item px-3">
                                                                                                        <Link to="/productlist" className="menu-link px-5">Productos</Link>
                                                                                                    </div>
                                                                                                    <div className="menu-item px-3">
                                                                                                        <Link to="/orderlist" className="menu-link px-5">Pedidos</Link>
                                                                                                    </div>
                                                                                                    {
                                                                                                        (userInfo.isAdmin || userInfo.isConsultant) &&
                                                                                                            <>
                                                                                                                <div className="menu-item px-3">
                                                                                                                    <Link to="/ordersnotpaid" className="menu-link px-5">Pedidos sin pagar</Link>
                                                                                                                </div>
                                                                                                                <div className="menu-item px-3">
                                                                                                                    <Link to="/deleteorder" className="menu-link px-5">Eliminar pedidos</Link>
                                                                                                                </div>
                                                                                                            </>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                            {
                                                                                                (process.env.REACT_APP_PAYMENT_METHOD === 'stripe' || process.env.REACT_APP_ALTERNATIVE_PAYMENT_METHOD === 'stripe' || process.env.REACT_APP_PAYMENT_METHOD === 'paycomet' || process.env.REACT_APP_ALTERNATIVE_PAYMENT_METHOD === 'paycomet') &&
                                                                                                <>
                                                                                                    <div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="left-start">
                                                                                                        <span className="menu-link px-5">
                                                                                                            <span className="menu-arrow-left"></span>
                                                                                                            <span className="menu-title">Suscripciones</span>
                                                                                                        </span>
                                                                                                        <div className="menu-sub menu-sub-dropdown w-175px py-4">
                                                                                                            <div className="menu-item px-3">
                                                                                                                <Link to="/subscriptionproductlist" className="menu-link px-5">Productos de suscripción</Link>
                                                                                                            </div>
                                                                                                            <div className="menu-item px-3">
                                                                                                                <Link to="/subscriptionlist" className="menu-link px-5">Suscripciones</Link>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="left-start">
                                                                                                        <span className="menu-link px-5">
                                                                                                            <span className="menu-arrow-left"></span>
                                                                                                            <span className="menu-title">Actividades extraescolares</span>                                                                                                            
                                                                                                        </span>
                                                                                                        <div className="menu-sub menu-sub-dropdown w-175px py-4">
                                                                                                            <div className="menu-item px-3">
                                                                                                                <Link to="/extracurricularactivitylist" className="menu-link px-5">Actividades extraescolares</Link>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </>
                                                                                            }
                                                                                        
                                                                                        {/* COMMENT IF CREATING ANDROID/IOS APP */}
                                                                                        <div className="menu-item px-3">
                                                                                            <Link to="/invoices" className="menu-link px-5">Facturas</Link>
                                                                                        </div>
                                                                                        </div>
                                                                                    </div>
                                                                            }
                                                                                
                                                                        </div>
                                                                    </div>
                                                                )

                                                            }
                                                            <div className="menu-item px-5">
                                                                <Link to="/orderhistory" className="menu-link px-5">Mis pedidos</Link>
                                                            </div>
                                                            {
                                                                (process.env.REACT_APP_PAYMENT_METHOD === 'stripe' || process.env.REACT_APP_ALTERNATIVE_PAYMENT_METHOD === 'stripe' || process.env.REACT_APP_PAYMENT_METHOD === 'paycomet' || process.env.REACT_APP_ALTERNATIVE_PAYMENT_METHOD === 'paycomet') &&
                                                                    <>
                                                                        <div className="menu-item px-5">
                                                                            <Link to="/subscriptionhistory" className="menu-link px-5">Mis suscripciones</Link>
                                                                        </div>
                                                                        <div className="menu-item px-5">
                                                                            <Link to="/extracurricularactivityhistory" className="menu-link px-5">Mis actividades extraescolares</Link>
                                                                        </div>
                                                                        <div className='menu-item px-5'>
                                                                            <Link to='/paymentmethod' className='menu-link px-5'>Mis tarjetas</Link>
                                                                        </div>
                                                                    </>
                                                            }
                                                            
                                                            <div className="menu-item px-5">
                                                                <Link to="/profile" className="menu-link px-5">Mi Perfil</Link>
                                                            </div>
                                                            <div className="menu-item px-5">
                                                                <Link to="/stopbullying" className="menu-link px-5">No al Bullying</Link>
                                                            </div>
                                                            <div className="menu-item px-5">
                                                                <Link to="#signout" className="menu-link px-5" onClick={signoutHandler}>Cerrar sesión</Link>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <div className="menu-item px-5">
                                                            <Link to="/" className="menu-link px-5">Iniciar sesión</Link>
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id='app-body'>
                            <Routes>
                                <Route path="/cart/:id" element={<CartScreen />} />
                                <Route path="/cart/" element={<CartScreen />} />
                                <Route exact path="/product/:id" element={<ProductScreen />} />
                                <Route exact path="/subscriptionProduct/:id" element={<SubscriptionProductScreen />} />
                                <Route path="/messages" element={<MessageScreen />} />
                                <Route path="/stopbullying" element={<StopbullyingScreen />} />
                                <Route exact path="/extracurricularactivity/:id" element={<ExtracurricularActivityScreen />} />
                                
                                <Route path="/" element={<PublicRoute><SigninScreen /></PublicRoute>} />
                                <Route path="/forgotpassword" element={<PublicRoute><ForgotPasswordScreen /></PublicRoute>} />
                                <Route path="/resetpassword/:id/:token" element={<PublicRoute><ResetPasswordScreen /></PublicRoute>} />

                                {/* <Route path="/register" element={<RegisterScreen />} /> */}
                                <Route path="/shipping" element={<ShippingAddressScreen />} />
                                <Route path="/payment" element={<PaymentMethodScreen />} />
                                <Route path="/placeorder" element={<PlaceOrderScreen />} />
                                <Route path="/order/:id" element={<OrderScreen />} />
                                <Route path="/order/:id/paid" element={<OrderPaidScreen />} />
                                <Route path="/orderhistory" element={<OrderHistoryScreen />} />
                                <Route path="/subscriptionhistory" element={<SubscriptionHistoryScreen />} />
                                <Route path="/extracurricularactivityhistory" element={<ExtracurricularActivityHistoryScreen />} />
                                <Route path="/extracurricularactivityinscription/:id/pay" element={<ExtracurricularActivityInscriptionScreen/>}/>
                                <Route path="/orderconfirmation" element={<OrderConfirmation />} />
                                <Route path="/category/:id" element={<CategoryScreen />} />
                                <Route path="/profile" element={<PrivateRoute><ProfileScreen /></PrivateRoute>} />
                                <Route path="/paymentmethod" element={<PrivateRoute><PaymentMethodListScreen /></PrivateRoute>} />

                                <Route path="/categorylist" element={<AdminRoute><CategoryListScreen /></AdminRoute>} />
                                <Route path="/categorie/:id/edit" element={<AdminRoute><EditCategoryScreen /></AdminRoute>} />
                                <Route path="/categorie/new" element={<AdminRoute><CreateCategoryScreen /></AdminRoute>} />

                                <Route path="/courselist" element={<AdminRoute><CourseListScreen /></AdminRoute>} />
                                <Route path="/course/new" element={<AdminRoute><CreateCourseScreen /></AdminRoute>} />
                                <Route path="/course/:id/edit" element={<AdminRoute><EditCourseScreen /></AdminRoute>} />
                                <Route path="/course/:id/absences" element={<AdminRoute><AbsencesCourseScreen /></AdminRoute>} />

                                <Route path="/productlist" element={<ConsultantRoute><ProductListScreen /></ConsultantRoute>} />
                                <Route exact path="/product/:id/edit" element={<ConsultantRoute><EditProductScreen /></ConsultantRoute>} />
                                <Route path="/product/new" element={<AdminRoute><CreateProductScreen /></AdminRoute>} />
                                <Route path="/subscriptionproductlist" element={<ConsultantRoute><SubscriptionProductListScreen /></ConsultantRoute>} />
                                <Route exact path="/subscriptionproduct/:id/edit" element={<ConsultantRoute><EditSubscriptionProductScreen /></ConsultantRoute>} />
                                <Route path="/subscriptionproduct/new" element={<AdminRoute><CreateSubscriptionProductScreen /></AdminRoute>} />
                                <Route path="/orderlist" element={<ConsultantRoute><OrderListScreen /></ConsultantRoute>} />
                                <Route path="/ordersnotpaid" element={<AdminRoute><OrdersNotPaidListScreen /></AdminRoute>} />
                                <Route path="/deleteorder" element={<AdminRoute><DeleteOrderSelectionScreen /></AdminRoute>} />
                                <Route path='/subscriptionlist' element={<ConsultantRoute><SubscriptionListScreen /></ConsultantRoute>} />
                                <Route path="/extracurricularactivitylist" element={<ConsultantRoute><ExtracurricularActivityListScreen /></ConsultantRoute>} />
                                <Route path="/extracurricularactivity/new" element={<AdminRoute><CreateExtracurricularActivityScreen /></AdminRoute>} />
                                <Route exact path="/extracurricularactivity/:id/edit" element={<ConsultantRoute><EditExtracurricularActivityScreen /></ConsultantRoute>} />
                                <Route path="/userlist" element={<AdminRoute><UserListScreen /></AdminRoute>} />
                                <Route path="/user/:id/edit" element={<AdminRoute><UserEditScreen /></AdminRoute>} />

                                <Route path="/studentlist" element={<AdminRoute><StudentListScreen /></AdminRoute>} />
                                <Route path="/student/:id/edit" element={<AdminRoute><StudentEditScreen /></AdminRoute>} />

                                <Route path="/attributelist" element={<AdminRoute><AttributeListScreen /></AdminRoute>} />
                                <Route path="/attribute/new" element={<AdminRoute><CreateAttributeScreen /></AdminRoute>} />
                                <Route path="/attribute/:id/edit" element={<AdminRoute><EditAttributeScreen /></AdminRoute>} />

                                <Route path="/pushnotification" element={<AdminRoute><PushNotificationScreeen /></AdminRoute>} />
                                <Route path="/coursenotification" element={<AdminRoute><CourseMessageScreen /></AdminRoute>} />
                                <Route path="/extracurricularactivitynotification" element={<AdminRoute><ExtracurricularActivityMessageScreen /></AdminRoute>} />
                                <Route path="/studentnotification/:id" element={<AdminRoute><StudentMessageScreen /></AdminRoute>} />

                                <Route path="/import" element={<AdminRoute><ImportScreeen /></AdminRoute>} />
                                <Route path="/home" element={<HomeScreen />} exact />
                                <Route path="/payorder/:id" element={<PayOrderScreen />} />
                                <Route path="/downloadorder/:id" element={<DownloadOrderScreen />} />
                                <Route path="/invoices" element={<ConsultantRoute><InvoiceScreen /></ConsultantRoute>} />
                            </Routes>
                        </div>
                        

                        <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
                            <div className="container-xxl d-flex flex-column flex-md-row align-items-center justify-content-between">
                                <div className="text-dark order-2 order-md-1">
                                    <div>
                                        <span className="text-muted fw-bold me-1">{(new Date().getFullYear())} ©</span>
                                        <Link to={"/#"} className="text-gray-800 text-hover-primary">SD School+</Link>
                                    </div>
                                    <div>
                                        <span className='text-muted me-1'>Si quieres eliminar tu cuenta, por favor acceda </span> <a href='mailto:soporte@smalldev.es?Subject=Eliminar%20cuenta%20de%20la%20aplicacion&body=Usuario%3A%0D%0AMotivo'>aquí</a>
                                    </div>
                                </div>
                                <ul className="menu menu-gray-600 menu-hover-primary fw-bold order-1">
                                    <li className="menu-item">
                                        <a href="https://www.facebook.com/sdschoolplus/" target="_blank" rel="noopener noreferrer" className="menu-link px-2">
                                            <img src="/assets/media/svg/brand-logos/facebook-3.svg" className="w-35px" alt="" />
                                        </a>
                                    </li>
                                    {/* <li className="menu-item">
                                        <a href="https://twitter.com/ciamariapuente?lang=es" target="_blank" rel="noopener noreferrer" className="menu-link px-2">
                                            <img src="/assets/media/svg/brand-logos/twitter.svg" className="w-35px" alt="" />
                                        </a>
                                    </li> */}
                                    <li className="menu-item">
                                        <a href="https://www.instagram.com/sdschoolplus/" target="_blank" rel="noopener noreferrer" className="menu-link px-2">
                                            <img src="/assets/media/svg/brand-logos/instagram-2-1.svg" className="w-35px" alt="" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="kt_drawer_chat" className="bg-body" data-kt-drawer="true" data-kt-drawer-name="chat" data-kt-drawer-activate="true" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'300px', 'md': '500px'}" data-kt-drawer-direction="end" data-kt-drawer-toggle="#kt_drawer_chat_toggle" data-kt-drawer-close="#kt_drawer_chat_close">
                    <div className="card w-100 rounded-0 border-0" id="kt_drawer_chat_messenger" style={contentCss}>
                        <div className="card-header pe-5" id="kt_drawer_chat_messenger_header">
                            <div className="card-title">
                                <div className="d-flex justify-content-center flex-column me-3">
                                    <Link to={"#"} className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">Carrito
                                        {cartItems.length > 0 && (
                                            <span className="fs-8 opacity-75 ps-3">{cartItems.length}</span>
                                        )}
                                    </Link>
                                </div>
                            </div>
                            <div className="card-toolbar">
                                <div className="btn btn-sm btn-icon btn-active-light-primary" id="kt_drawer_chat_close">
                                    <span className="svg-icon svg-icon-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black" />
                                            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black" />
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="card-body" id="kt_drawer_chat_messenger_body">
                            {cartItems.length > 0 ? (
                                <>
                                    <div className="scroll-y me-n5 pe-5" data-kt-element="messages" data-kt-scroll="true" data-kt-scroll-activate="true" data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer" data-kt-scroll-wrappers="#kt_drawer_chat_messenger_body" data-kt-scroll-offset="0px">
                                        {cartItems.map((item, i) => (

                                            <div key={i} className="d-flex justify-content-start mb-10">
                                                <div className="d-flex flex-column align-items-start">
                                                    <div className="d-flex align-items-center mb-2">
                                                        <div className="symbol symbol-35px symbol-circle">
                                                            <img src={item.image ? item.image : "/Grupo_183.png"} alt={item.name} />
                                                        </div>
                                                        <div className="ms-5">
                                                            <Link to={`/product/${item.product}`} className="fs-5 fw-bolder text-gray-900 text-hover-primary me-1">
                                                                {item.name}
                                                                {
                                                                    item.attributes?.length > 0
                                                                        ?
                                                                        `, ${attributes?.find(att => att._id === item.attributes[0].attribute)?.name}: ${item.attributes[0].value}`
                                                                        :
                                                                        ''
                                                                }
                                                            </Link>
                                                            <span className="text-muted fs-7 my-3 d-block">{item.price}€ x {item.qty} = {(item.qty * item.price).toFixed(2)}€</span>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        ))}
                                        <div className="d-grid gap-2">
                                            <Link to={'/cart'} className="btn btn-primary btn-block"> Ver carrito</Link>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div>Carrito vacío</div>
                            )}

                        </div>
                    </div>
                </div>
                <div className="bg-body" id="kt_activities" data-kt-drawer="true" data-kt-drawer-name="activities" data-kt-drawer-activate="true" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'300px', 'md': '500px'}" data-kt-drawer-direction="end" data-kt-drawer-toggle="#kt_activities_toggle" data-kt-drawer-close="#kt_activities_close">
                <div className="card w-100 rounded-0 border-0" id="kt_activities" style={contentCss}>
                        <div className="card-header pe-5" id="kt_activities_header">
                            <div className="card-title">
                                <div className="me-3">
                                    <div>
                                        <Link to={"#"} className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">Notificaciones
                                        </Link>
                                    </div>
                                    {notifications.filter(noti => noti.read === false).length > 0
                                    ?
                                        <div>
                                            <Link to={'#'} onClick={viewAllNotifications} className="text-muted"><small>Marcar todas como leidas</small></Link>
                                        </div>
                                    :
                                        <></>
                                    }
                                </div>
                            </div>
                            <div className="card-toolbar">
                                <div className="btn btn-sm btn-icon btn-active-light-primary" id="kt_activities_close">
                                    <span className="svg-icon svg-icon-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black" />
                                            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black" />
                                        </svg>                                        
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="card-body" id="kt_activities_body">
                                {notifications.length > 0
                                ?
                                    <div className="scroll-y me-n5 pe-5" data-kt-element="activities" data-kt-scroll="true" data-kt-scroll-activate="true" data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_activities_header, #kt_activities_footer" data-kt-scroll-wrappers="#kt_activities_body" data-kt-scroll-offset="0px">
                                        {notifications.map((item, i) => (
                                            <div key={i} className="card my-5 border">
                                                <div className='card-header'>
                                                    <div className="card-title">
                                                        <span className='fw-bolder text-gray-900'>{item.title}</span>
                                                        {item.read === false
                                                        ?
                                                            <Link title='Marcar como leida' to={'#'} onClick={() => viewNotification(item._id)}><i className="bi bi-eye-fill text-success m-5"></i></Link>
                                                        :
                                                            <></>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='card-body'>
                                                    {item.description}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                :
                                    <></>
                                }

                        </div>
                    </div>
                </div>
            </BrowserRouter>

        </>
    );

}



export default App;