import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { listPaymentMethods } from '../actions/paymentMethodActions';
import { PAYMENTMETHOD_CREATE_RESET } from '../constants/paymentMethodConstants';
import { getSubscriptionStatus, subscribe } from '../actions/paymentActions';
import { PAYMENT_SUBSCRIPTION_RESET, PAYMENT_SUBSCRIPTION_STATUS_RESET } from '../constants/paymentConstants';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

export const PayCometSubscriptionButton = ({subscriptionProduct, fee, student}) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const [selectedCard, setSelectedCard] = useState("null")
	const [waitingForPayment, setWaitingForPayment] = useState(false)

	const [savePaymentMethod, setSavePaymentMethod] = useState(true)

	const {paymentMethods} = useSelector(state => state.paymentmethodList)

	const { loading: loadingSubscriptionCreate, error: errorsubscriptionCreate, result } = useSelector(state => state.paymentSubscription)

	const { status } = useSelector(state => state.subscriptionStatus)

	const paymentmethodCreate = useSelector((state) => state.paymentmethodCreate);
	const { paymentmethod } = paymentmethodCreate;

	const handleSubmit = (e) => {
		e.preventDefault()
		setWaitingForPayment(true)
		dispatch(subscribe({
			subscriptionProduct: subscriptionProduct._id,
			student: student,
			fee: fee._id,
			paymentMethod: selectedCard === "null" ? null : selectedCard,
			tokenize: savePaymentMethod
		}))
	};

	useEffect(() => {
		dispatch(listPaymentMethods())
		dispatch({type: PAYMENT_SUBSCRIPTION_RESET})
	}, [dispatch])

	useEffect(() => {
        if (paymentMethods?.length > 0) {
            if (paymentmethod) {
				setSelectedCard(paymentMethods[paymentMethods.length - 1]?._id)	
				dispatch({ type: PAYMENTMETHOD_CREATE_RESET});
            } else {
                setSelectedCard(paymentMethods[0]?._id || 'null')
            }
        }
    }, [paymentMethods, paymentmethod])

	useEffect(() => {
		if (result) {
			const createInterval = () => {
				const intervalFn = () => {
					clearInterval(window.orderSubscriptionStatus)
					dispatch({ type: 'PAYMENT_STATUS_RESET' })
				}
		
				window.orderSubscriptionStatusIndex = 0
		
				window.orderSubscriptionStatus = setInterval(() => {
					window.orderSubscriptionStatusIndex++
					dispatch(getSubscriptionStatus(result.subscription))
					if (window.orderSubscriptionStatusIndex > 600) {
						intervalFn()
					}
				}, 1000)
			}

			createInterval()
			if (result.challengeUrl) {
				window.open(result.challengeUrl, "_blank")
			}
		}
	}, [result])

	useEffect(() => {
		if (status?.isPaid) {
			clearInterval(window.orderSubscriptionStatus)
			setWaitingForPayment(false)
			dispatch({ type: PAYMENT_SUBSCRIPTION_RESET })
			dispatch({ type: PAYMENT_SUBSCRIPTION_STATUS_RESET })
			Swal.fire({
				position: 'center',
				icon: 'success',
				title: 'Suscripción creada correctamente',
				showConfirmButton: false,
				timer: 1500
			})
			navigate('/subscriptionhistory')
		}
	}, [status, dispatch, navigate])

	return (
		<div className='d-grid gap-2 my-7'>
			{
				paymentMethods?.length > 0 &&
				<div className='my-5'>
					<label className="form-label">Seleccione tarjeta</label>
					<select className='form-select' name="card" value={selectedCard} onChange={(e) => setSelectedCard(e.target.value)}>
						{
							paymentMethods.map((paymentMethod, ind) => (
								<option key={ind} value={paymentMethod._id}>{paymentMethod.name || paymentMethod.last4}</option>
							))
						}
						<option value="null">Otra</option>
					</select>
				</div>
			}
			{
				selectedCard === 'null' &&
				<>
					<div className="my-5">
						<label className='form-check form-switch form-check-custom form-check-solid'>
							<input className='form-check-input' type='checkbox' checked={savePaymentMethod} onChange={e => setSavePaymentMethod(e.target.checked)} />
							<span className='form-check-label fw-bold me-4'>Guardar tarjeta</span>
						</label>
					</div>
				</>
			}
			<div className="d-grid gap-2 mt-3">
				<button disabled={waitingForPayment || loadingSubscriptionCreate} type="button" className="btn btn-primary" onClick={handleSubmit}>
					{
						waitingForPayment || loadingSubscriptionCreate
						?
							<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
						:
							<span id="button-text">Pagar</span>
					}
				</button>
			</div>
		</div>
	)
}