export const PAYMENT_STATUS_RESET = 'PAYMENT_STATUS_RESET';
export const PAYMENT_STATUS_SUCCESS = 'PAYMENT_STATUS_SUCCESS';
export const PAYMENT_STATUS_FAIL = 'PAYMENT_STATUS_FAIL';
export const PAYMENT_STATUS_REQUEST = 'PAYMENT_STATUS_REQUEST';

export const PAYMENT_CHALLENGE_URL_RESET = 'PAYMENT_CHALLENGE_URL_RESET';
export const PAYMENT_CHALLENGE_URL_SUCCESS = 'PAYMENT_CHALLENGE_URL_SUCCESS';
export const PAYMENT_CHALLENGE_URL_FAIL = 'PAYMENT_CHALLENGE_URL_FAIL';
export const PAYMENT_CHALLENGE_URL_REQUEST = 'PAYMENT_CHALLENGE_URL_REQUEST';

export const PAYMENT_ORDER_RESET = 'PAYMENT_ORDER_RESET';
export const PAYMENT_ORDER_SUCCESS = 'PAYMENT_ORDER_SUCCESS';
export const PAYMENT_ORDER_FAIL = 'PAYMENT_ORDER_FAIL';
export const PAYMENT_ORDER_REQUEST = 'PAYMENT_ORDER_REQUEST';

export const PAYMENT_SUBSCRIPTION_RESET = 'PAYMENT_SUBSCRIPTION_RESET';
export const PAYMENT_SUBSCRIPTION_SUCCESS = 'PAYMENT_SUBSCRIPTION_SUCCESS';
export const PAYMENT_SUBSCRIPTION_FAIL = 'PAYMENT_SUBSCRIPTION_FAIL';
export const PAYMENT_SUBSCRIPTION_REQUEST = 'PAYMENT_SUBSCRIPTION_REQUEST';

export const PAYMENT_SUBSCRIPTION_STATUS_RESET = 'PAYMENT_SUBSCRIPTION_STATUS_RESET';
export const PAYMENT_SUBSCRIPTION_STATUS_SUCCESS = 'PAYMENT_SUBSCRIPTION_STATUS_SUCCESS';
export const PAYMENT_SUBSCRIPTION_STATUS_FAIL = 'PAYMENT_SUBSCRIPTION_STATUS_FAIL';
export const PAYMENT_SUBSCRIPTION_STATUS_REQUEST = 'PAYMENT_SUBSCRIPTION_STATUS_REQUEST';