
const { PAYMENT_STATUS_RESET, PAYMENT_STATUS_SUCCESS, PAYMENT_STATUS_FAIL, PAYMENT_STATUS_REQUEST, PAYMENT_CHALLENGE_URL_SUCCESS, PAYMENT_CHALLENGE_URL_RESET, PAYMENT_CHALLENGE_URL_FAIL, PAYMENT_CHALLENGE_URL_REQUEST, PAYMENT_ORDER_REQUEST, PAYMENT_ORDER_RESET, PAYMENT_ORDER_SUCCESS, PAYMENT_ORDER_FAIL, PAYMENT_SUBSCRIPTION_REQUEST, PAYMENT_SUBSCRIPTION_RESET, PAYMENT_SUBSCRIPTION_SUCCESS, PAYMENT_SUBSCRIPTION_FAIL, PAYMENT_SUBSCRIPTION_STATUS_REQUEST, PAYMENT_SUBSCRIPTION_STATUS_RESET, PAYMENT_SUBSCRIPTION_STATUS_SUCCESS, PAYMENT_SUBSCRIPTION_STATUS_FAIL } = require('../constants/paymentConstants')

export const paymentStatusReducer = (state = { loading: false, status: null, },action) => {
	switch (action.type) {
		case PAYMENT_STATUS_REQUEST:
			return { ...state, loading: true }
		case PAYMENT_STATUS_RESET:
			return { ...state, loading: false, status: null, error: null };
		case PAYMENT_STATUS_SUCCESS:
			return { ...state, loading: false, status: action.payload, error: null };
		case PAYMENT_STATUS_FAIL:
			return { ...state, loading: false, error: action.payload };
		default:
			return state;
	}
}

export const paymentCreateReducer = (state = { loading: false, payment: null, error: null},action) => {
	switch (action.type) {
		case PAYMENT_CHALLENGE_URL_REQUEST:
			return { ...state, loading: true }
		case PAYMENT_CHALLENGE_URL_RESET:
			return { ...state, loading: false, payment: null, error: null };
		case PAYMENT_CHALLENGE_URL_SUCCESS:
			return { ...state, loading: false, payment: action.payload, error: null };
		case PAYMENT_CHALLENGE_URL_FAIL:
			return { ...state, loading: false, error: action.payload, payment: null };
		default:
			return state;
	}
}

export const paymentOrderReducer = (state = { loading: false, result: null, error: null},action) => {
	switch (action.type) {
		case PAYMENT_ORDER_REQUEST:
			return { ...state, loading: true }
		case PAYMENT_ORDER_RESET:
			return { ...state, loading: false, result: null, error: null };
		case PAYMENT_ORDER_SUCCESS:
			return { ...state, loading: false, result: action.payload, error: null };
		case PAYMENT_ORDER_FAIL:
			return { ...state, loading: false, error: action.payload, result: null };
		default:
			return state;
	}
}

export const paymentSubscriptionReducer = (state = { loading: false, result: null, error: null},action) => {
	switch (action.type) {
		case PAYMENT_SUBSCRIPTION_REQUEST:
			return { ...state, loading: true }
		case PAYMENT_SUBSCRIPTION_RESET:
			return { ...state, loading: false, result: null, error: null };
		case PAYMENT_SUBSCRIPTION_SUCCESS:
			return { ...state, loading: false, result: action.payload, error: null };
		case PAYMENT_SUBSCRIPTION_FAIL:
			return { ...state, loading: false, error: action.payload, result: null };
		default:
			return state;
	}
}

export const subscriptionStatusReducer = (state = { loading: false, status: null, },action) => {
	switch (action.type) {
		case PAYMENT_SUBSCRIPTION_STATUS_REQUEST:
			return { ...state, loading: true }
		case PAYMENT_SUBSCRIPTION_STATUS_RESET:
			return { ...state, loading: false, status: null, error: null };
		case PAYMENT_SUBSCRIPTION_STATUS_SUCCESS:
			return { ...state, loading: false, status: action.payload, error: null };
		case PAYMENT_SUBSCRIPTION_STATUS_FAIL:
			return { ...state, loading: false, error: action.payload };
		default:
			return state;
	}
}