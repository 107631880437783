import Axios from 'axios';
import { PAYMENT_CHALLENGE_URL_FAIL, PAYMENT_CHALLENGE_URL_REQUEST, PAYMENT_CHALLENGE_URL_SUCCESS, PAYMENT_ORDER_FAIL, PAYMENT_ORDER_REQUEST, PAYMENT_ORDER_SUCCESS, PAYMENT_STATUS_FAIL, PAYMENT_STATUS_REQUEST, PAYMENT_STATUS_SUCCESS, PAYMENT_SUBSCRIPTION_FAIL, PAYMENT_SUBSCRIPTION_REQUEST, PAYMENT_SUBSCRIPTION_STATUS_FAIL, PAYMENT_SUBSCRIPTION_STATUS_REQUEST, PAYMENT_SUBSCRIPTION_STATUS_SUCCESS, PAYMENT_SUBSCRIPTION_SUCCESS } from '../constants/paymentConstants';

export const getPaymentStatus = (paymentReference) => async (dispatch, getState) => {
	dispatch({ type: PAYMENT_STATUS_REQUEST });
	const {userSignin: { userInfo }} = getState();
	try {
		const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/paycomet/paymentStatus/' + paymentReference, {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			}
		});
		dispatch({ type: PAYMENT_STATUS_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: PAYMENT_STATUS_FAIL, payload: error.message });
	}
}

export const getOrderStatus = (orderId) => async (dispatch, getState) => {
	dispatch({ type: PAYMENT_STATUS_REQUEST });
	const {userSignin: { userInfo }} = getState();
	try {
		const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/paycomet/orderStatus/' + orderId, {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			}
		});
		dispatch({ type: PAYMENT_STATUS_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: PAYMENT_STATUS_FAIL, payload: error.message });
	}
}

export const getCardTokenUrl = () => async (dispatch, getState) => {
	dispatch({ type: PAYMENT_CHALLENGE_URL_REQUEST });

	const { userSignin: { userInfo } } = getState();

	try {
		const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/paycomet/getCardTokenUrl', {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			}
		})
		dispatch({ type: PAYMENT_CHALLENGE_URL_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: PAYMENT_CHALLENGE_URL_FAIL,
			payload: error.response && error.response.data.message? error.response.data.message: error.message,
		});
	}
}

export const getOrderPaymentLink = (orderId) => async (dispatch, getState) => {
	dispatch({ type: PAYMENT_CHALLENGE_URL_REQUEST });
	const { userSignin: { userInfo } } = getState();
	try {
		const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/paycomet/getOrderPaymentLink/' + orderId, {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			}
		})
		dispatch({ type: PAYMENT_CHALLENGE_URL_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: PAYMENT_CHALLENGE_URL_FAIL,
			payload: error.response && error.response.data.message? error.response.data.message: error.message,
		});
	}
}

export const payOrder = (orderId, selectedCard) => async (dispatch, getState) => {
	dispatch({ type: PAYMENT_ORDER_REQUEST });
	const { userSignin: { userInfo } } = getState();
	try {
		const { data } = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/paycomet/payOrder', {order: orderId, selectedCard}, {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			}
		})
		dispatch({ type: PAYMENT_ORDER_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: PAYMENT_ORDER_FAIL,
			payload: error.response && error.response.data.message? error.response.data.message: error.message,
		});
	}
}

export const subscribe = (subscriptionData) => async (dispatch, getState) => {
	dispatch({ type: PAYMENT_SUBSCRIPTION_REQUEST })
	const { userSignin: { userInfo } } = getState();

	try {
		const { data } = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/paycomet/subscribe', subscriptionData, {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			}
		})
		dispatch({ type: PAYMENT_SUBSCRIPTION_SUCCESS, payload: data })
	} catch (error) {
		dispatch({
			type: PAYMENT_SUBSCRIPTION_FAIL,
			payload: error.response && error.response.data.message? error.response.data.message: error.message,
		});
	}
}

export const getSubscriptionStatus = (subscriptionId) => async (dispatch, getState) => {
	dispatch({ type: PAYMENT_SUBSCRIPTION_STATUS_REQUEST });
	const {userSignin: { userInfo }} = getState();
	try {
		const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/paycomet/subscriptionStatus/' + subscriptionId, {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			}
		});
		dispatch({ type: PAYMENT_SUBSCRIPTION_STATUS_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: PAYMENT_SUBSCRIPTION_STATUS_FAIL, payload: error.message });
	}
}